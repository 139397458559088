<template>
    <footer class="footer">
        <p>
            <a href="https://www.qyyhl.com/aff/ESHBGWYG" target="_blank">
                <img src="https://img.shields.io/badge/本站由清羽云CDN提供加速防御支持-green?style=flat&logo=Claris" alt="加速支持" />
            </a>
        </p>
    </footer>
</template>
  
<script>
export default {
    name: "InterFooter",
};
</script>
  
<style scoped>
.footer {
    text-align: center;
    padding: 10px 0;
    background: #282c34;
    color: white;
    width: 100%;
    /* 确保页脚宽度 */
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}
</style>
  