<template>
  <div class="container">
    <div class="subtitle">— INTER-KNOT —</div>
    <h1 class="title">ZENLESSZONEZERO</h1>
    <div class="subtitle">「绳网开发中,请耐心等待」</div>
    <div class="play-button-container">
      <div class="play-button" @click="playAudio">Play</div>
    </div>
    <audio id="audio">
      <source src="../audio/春日影.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
export default {
  name: 'InterKnot',
  methods: {
    playAudio() {
      var audio = document.getElementById("audio");
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "SmileySans";
  src: url("../assets/SmileySans-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.container {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* 确保内容部分占据视口的最小高度 */
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

.subtitle {
  font-size: 24px;
  font-weight: lighter;
  margin: 10px 0;
}

.play-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 30px;
}

.play-button {
  width: 100px;
  height: 100px;
  border: 4px dashed rgb(24, 122, 233);
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(25, 78, 177);
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
