<template>
  <div id="app">
    <!-- 核心内容：inter-knot 组件居中显示 -->
    <div class="content">
      <inter-knot />
    </div>
    <!-- 页脚，滚动时显示 -->
    <inter-footer />
  </div>
</template>

<script>
import InterKnot from "./components/inter-knot.vue";
import InterFooter from "./components/InterFooter.vue";

export default {
  name: "App",
  components: {
    InterKnot,
    InterFooter,
  },
};
</script>

<style>
/* 保证全局字体加载 */
@font-face {
  font-family: "SmileySans";
  src: url("./assets/SmileySans-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* 保证页面背景图占满整个视口 */
html,
body {
  height: 100%;
  /* 确保视口高度 */
  margin: 0;
  font-family: "SmileySans", Arial, sans-serif;
  background-image: url("./assets/0001.png");
  /* 背景图 */
  background-size: cover;
  /* 背景图自适应 */
  background-position: center;
  display: flex;
  flex-direction: column;
}

/* 确保页面主体内容区域的正常显示 */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* 最小高度为视口高度 */
}

/* content：居中显示的核心组件 */
.content {
  display: flex;
  flex: 1;
  /* content 占据剩余空间 */
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

/* 页脚固定在底部，滚动时才显示 */
footer {
  margin-top: auto;
  /* 页脚始终在底部 */
}

/* 定义整个滚动条的宽度 */
::-webkit-scrollbar {
  width: 8px;
  /* 垂直滚动条宽度 */
  height: 18px;
  /* 水平滚动条高度 */
}

/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background: rgba(48, 116, 219, 0.1);
  /* 轨道背景色 */
  border-radius: 5px;
}

/* 定义滚动条滑块（thumb）的样式 */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #2f78cc 25%, #ffffff 25%, #ffffff 50%, #2f78cc 50%, #2f78cc 75%, #ffffff 75%, #ffffff);
  background-size: 10px 10px;
  /* 定义斜条纹的大小 */
  border-radius: 10px;
  /* 滑块圆角 */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* 滑块边框 */
  transition: background-color 0.3s ease;
  /* 滑块的过渡效果 */
}

/* 滑块的hover效果 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(17, 208, 233, 0.7);
  /* 滑块hover时的颜色 */
}

/* 滚动条在IE浏览器中的样式，适配较旧的浏览器 */
body {
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
}</style>
